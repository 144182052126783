import React from "react"
import { useSelector } from "react-redux"

const ClientBlock = () => {

  const { language } = useSelector(state => state.global)

  const clients = useSelector(({ clients }) => clients.clients)


  return (
    <section className="career_block client_block">
      <div className="container">
        <div className="row text_side p-0">
          <div className="txt_block">
            <div className="lftbrdr">
              <h6>{clients.title[language]}</h6>
              <h2>{clients.text[language]}</h2>
              <p>{clients.paragraph[language]}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ClientBlock
