import React from "react"
import Banner from "./Banner"
import ClientBlock from "./ClientBlock"
import ClientList from "./ClientList"
import Explore from "../Common/Explore"

const Clients = () => (
  <>
    <Banner />
    <ClientBlock />
    <ClientList />
    <Explore />
  </>
)

export default Clients
