import React from "react"
import { Link } from "gatsby"
import { useSelector } from "react-redux"

const ClientList = () => {


  const { language } = useSelector(state => state.global)

  const clients = useSelector(({ clients }) => clients.clients)

  return (
    <section className="client_list">
      <div className="container">
        <div className="client_logo_set">
          {clients.list.map(client => (
            <div className="client_boxes">
              <img alt="img" src={client.img.sourceUrl} />
              <h2>{client.industry[language]}</h2>
              <p>{client.text[language]}</p>
              <Link to={`${client.link}`}>
                {client.anchorText[language]}
                <i className="arrow arrowtwo" />
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default ClientList
